<template>
  <div class="upload-pushu">
    <div class="content-inner">
      <div class="pushu-header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/web/user/my-pushu' }"
            >我的谱书</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/web/user/upload' }"
            >上传谱书</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <el-form
        ref="upload-form"
        :inline="true"
        :model="uploadForm"
        class="upload-form"
      >
        <el-form-item class="upload-form-item" label="题名（家谱名称）">
          <el-input v-model="uploadForm.title"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="编号">
          <el-input v-model="uploadForm.sn"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="价格">
          <el-input v-model="uploadForm.price"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="描述">
          <el-input v-model="uploadForm.description"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="卷数">
          <el-input v-model="uploadForm.juanshu"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="谱籍地">
          <el-input v-model="uploadForm.pujidi"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="始祖">
          <el-input v-model="uploadForm.shizu"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="始迁祖">
          <el-input v-model="uploadForm.shiqianzu"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="支系">
          <el-input v-model="uploadForm.timing"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="修撰者">
          <el-input v-model="uploadForm.xiuzhuanzhe"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="版本年">
          <el-input v-model="uploadForm.banbennian"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="堂号">
          <el-input v-model="uploadForm.tanghao"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="字辈">
          <el-input v-model="uploadForm.zibei"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="编撰日期">
          <el-input v-model="uploadForm.zhuanxieriqi"></el-input>
        </el-form-item>
        <el-form-item class="upload-form-item" label="发源地">
          <el-input v-model="uploadForm.fayuandi"></el-input>
        </el-form-item>
      </el-form>
      <div class="upload-input upload-form-item">
        <label class="el-form-item__label">上传PDF</label>
        <el-upload
          class="upload-area"
          name="files"
          :disabled="uploading"
          :show-file-list="false"
          :action="uploadApi"
          :on-success="handleUploaded"
          :before-upload="handleBeforeUplaod"
        >
          <el-button
            :loading="uploading"
            class="upload-btn"
            size="medium"
            type="primary"
            >{{ uploading ? "上传中" : "选择文件" }}</el-button
          >
          <!-- <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div> -->
          <span class="upload-tips">{{
            uploadForm.source_file || "未选择任何文件"
          }}</span>
        </el-upload>
      </div>
      <div class="submit-area">
        <el-button
          class="submit-btn"
          size="medium"
          type="primary"
          @click="handleSubmit"
          >确认上传</el-button
        >
        <el-button
          class="cancel-btn"
          size="medium"
          type="primary"
          @click="$router.back()"
          >取消</el-button
        >
      </div>
    </div>
    <pay-dialog ref="pay-dialog" @success="fetchUserInfo" />
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Upload
} from "element-ui";
import { mapActions } from "vuex";
import { apiRoot } from "@/config";
import PayDialog from "@/components/web/PayDialog";
import materialApis from "@/apis/material";

const initForm = () => ({
  title: "",
  sn: "",
  price: "",
  description: "",
  source_file: "",
  juanshu: "",
  pujidi: "",
  shizu: "",
  shiqianzu: "",
  timing: "",
  xiuzhuanzhe: "",
  banbennian: "",
  tanghao: "",
  zibei: "",
  zhuanxieriqi: "",
  fayuandi: ""
});

export default {
  name: "Upload",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    "el-breadcrumb": Breadcrumb,
    "el-breadcrumb-item": BreadcrumbItem,
    "el-upload": Upload,
    PayDialog
  },
  data() {
    return {
      uploadForm: initForm(),
      fileList: [],
      uploading: false,
      uploadApi:
        process.env.NODE_ENV === "development"
          ? `/api/material/upload`
          : `${apiRoot}material/upload`
    };
  },
  activated() {
    this.uploadForm = initForm();
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),

    handleBeforeUplaod() {
      this.uploading = true;
    },

    handleUploaded(res) {
      const {
        data: { fileurl },
        msg
      } = res;
      if (fileurl) {
        this.uploadForm.source_file = fileurl;
      } else {
        this.$message.error(msg);
      }
      this.uploading = false;
      // console.log(fileurl);
    },
    handleSubmit() {
      this.$refs["upload-form"].validate(async valid => {
        if (valid) {
          console.log(this.uploadForm);
          try {
            await materialApis.add(this.uploadForm);
            this.$message.success("提交成功");
            this.$router.back();
          } catch (error) {
            console.log("error", error);
            this.$refs["pay-dialog"].show();
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

//
<style lang="scss">
.upload-form-item {
  margin-bottom: 30px;

  .el-form-item__label {
    text-align: left;
    font-size: 12px;
    color: #333;

    &::after {
      content: "：";
    }
  }
}
</style>

<style lang="scss" scoped>
.upload-pushu {
  padding: 20px;
  min-height: calc(100% - 50px);
  box-sizing: border-box;

  .content-inner {
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    border-radius: 4px;
    // padding: 0 20px;
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 20px;
  }

  .pushu-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .title-text {
      font-size: 14px;
      color: #666666;
    }
  }

  .upload-form {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding: 0 20px;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .upload-form-item {
      flex: none;
      width: 32.4%;
      display: flex;
      flex-flow: column nowrap;

      .el-form-item__label {
        text-align: left;
      }
    }
  }

  .upload-input {
    padding: 20px;
    padding-top: 0;
    margin-bottom: 0;
    display: flex;
    position: relative;
    flex-flow: column wrap;
    // align-items: center;
    .upload-btn {
      background-color: #148bfa;
    }
    .upload-tips {
      margin-left: 5px;
      color: #666666;
      font-size: 14px;
    }
  }

  .submit-area {
    display: flex;
    justify-content: center;
  }
}
</style>
